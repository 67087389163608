<template>
  <div>
    <page-heading :heading="$t('users')">
      <template v-slot:actions>
        <app-button :label="$t('create')" @click="createUser"></app-button>
      </template>
    </page-heading>

    <form-open class="grid lg:grid-cols-4 grid-cols-2 gap-4 my-4" @submit="submit">
      <form-text
        :label="$t('search')"
        :required="false"
        :show-validation-label="false"
        icon="search"
        input-id="search"
        v-model="query.filter.search"
      />
      <form-select
        :label="$t('status')"
        :multiple="true"
        :options="data.statuses"
        :placeholder="$t('select_placeholder_all')"
        :required="false"
        :show-validation-label="false"
        @input="submit"
        input-id="status"
        v-model="query.filter.status"
      />
      <app-button class="hidden"/>
    </form-open>

    <loading-wrapper>
      <app-table :items="items">
        <template
          v-slot:headings
        >
          <app-th-sortable :label="$t('model.user.name')" name="last_name" :sort="query.sort" @updateSort="updateSort"/>
          <app-th :heading="$t('model.user.permissions')"/>
          <app-th :heading="$t('model.user.last_login')"/>
          <app-th :heading="$t('model.user.sessions')"/>
          <app-th-sortable :label="$t('model.user.status')" name="status" :sort="query.sort" @updateSort="updateSort"/>
          <app-th :heading="$t('model.user.asset_reports')"/>
          <app-th></app-th>
        </template>

        <template
          v-slot:row="{ item }"
        >
          <app-td>
            <div class="inline-flex flex-col">
              <a href="#" class="link" @click.prevent="editUser(item.id)">{{ item.name }}</a>
              <span class="text-xs text-gray-400">{{ item.username }}</span>
            </div>
          </app-td>
          <app-td class="space-x-2 truncate">
            <badge :label="item.permissions.length"/>
          </app-td>
          <app-td :data="item.last_login_diff ? item.last_login_diff : $t('never')"/>
          <app-td>
            <div class="flex space-x-2">
              <div class="flex gap-1">
                <app-svg class="w-4" svg="desktop-computer"/>
                <span class="text-xs">{{ item.sessions_count }}</span>
              </div>
              <div class="flex gap-1">
                <app-svg class="w-4" svg="device-mobile"/>
                <span class="text-xs">{{ item.tokens_count }}</span>
              </div>
            </div>
          </app-td>
          <app-td>
            <badge :label="$t(`user_status.${item.status}`)" :theme="getStatusTheme(item.status)"/>
          </app-td>
          <app-td>
            <badge class="cursor-pointer link" :label="item.asset_reports_count" @click="viewUserAssetReports(item.id)"/>
          </app-td>
          <app-td>
            <app-options>
              <div>
                <app-option-edit @click="editUser(item.id)"/>
                <app-option
                  :class="{
                    'disabled': (item.sessions_count + item.tokens_count) === 0,
                  }"
                  :label="$t('log_out_user')"
                  svg="logout"
                  @click="logoutUser(item.id)"
                />
              </div>
              <app-option-delete @click="openDeleteModal(item.id)"/>
            </app-options>
          </app-td>
        </template>
      </app-table>
      <pagination
        :meta="meta"
        :page="query.page"
        v-if="meta"
        @pageUpdated="updatePage"
      />
    </loading-wrapper>

    <modal-delete :active="modals.delete" @close="closeModal('delete')" @delete="deleteModel"
      :title="$t('delete')"
      :text="$t('delete_thing_text', { thing: model.name })"
    />
  </div>
</template>

<script>
import ApiUserService from '@/services/api/user';
import ApiUserStatusService from '@/services/api/user_status';
import Common from '@/mixins/common';

export default {
  computed: {},
  data() {
    return {
      data: {
        statuses: [],
      },
      items: [],
      meta: {},
      modals: {
        delete: false,
      },
      modelId: null,
      model: {},
      query: {
        filter: {
          status: [
            1,
          ],
        },
        page: 1,
        sort: [
          'last_name',
        ],
        ...this.$route.query,
      },
    };
  },
  mounted() {
    ApiUserStatusService.getStatuses()
      .then((response) => {
        const { data } = response.data;

        this.data.statuses = this.toSelectOptions(data, 'status', 'id', (label) => `user_status.${label}`);
      })
      .catch(() => {});

    this.get();
  },
  methods: {
    createUser() {
      this.$router.push({
        name: 'users.create',
      });
    },
    deleteModel() {
      ApiUserService.deleteOrganisationUser(this.modelId)
        .then(() => {
          this.closeModals();
          this.get();
        })
        .catch(() => {});
    },
    editUser(userId) {
      this.$router.push({
        name: 'users.edit',
        params: {
          userId,
        },
      });
    },
    get() {
      ApiUserService.getOrganisationUsers({
        params: {
          ...this.query,
          include: [
            'assetReportsCount',
            // 'assetReportsActionReqCount',
            'lastLogin',
            'permissions',
            'sessionsCount',
            'tokensCount',
          ],
        },
      }).then((response) => {
        const { data, meta } = response.data;

        this.items = data;
        this.meta = meta;
      }).catch(() => {});
    },
    getListings() {
      this.get();
    },
    getStatusTheme(statusId) {
      return {
        1: 'success',
        9: 'warning',
        10: 'error',
      }[statusId];
    },
    logoutUser(userId) {
      ApiUserService.logoutUser(userId)
        .then(() => {
          this.get();
        })
        .catch(() => {});
    },
    openDeleteModal(modelId) {
      ApiUserService.getOrganisationUser(modelId)
        .then((response) => {
          const { data } = response.data;

          this.modelId = modelId;
          this.model = data;

          this.openModal('delete');
        })
        .catch(() => {});
    },
    routeQueryUpdated() {
      this.get();
    },
    submit() {
      this.query.page = 1;
      this.get();
    },
    viewUserAssetReports(userId) {
      this.$router.push({
        name: 'asset_reports',
        query: {
          filter: {
            user_id: userId,
          },
        },
      });
    },
  },
  mixins: [
    Common,
  ],
};
</script>
